import CardView from "./components/cards/CardView";

function App() {
  
  return (
    <div className="App">
      <CardView />
    </div>
  );
}

export default App;

