import React, { useEffect, useState } from 'react';
import Card from './Card';
import SelectedCard from '../selected-card/SelectedCard';
import './cards.scss';
import logo from '../../assets/voco it kool logod RGB-02.png';

function CardView() {
    const [data, setData] = useState([]);
    const [selectedCard, setSelectedCard] = useState(null);
    const handleCardSelect = (card) => {
        setSelectedCard(card);
    };

    useEffect(() => {
        const getCards = async () => {
          const response = await fetch(
            'https://vocokaart.ikt.khk.ee/data.php'
          );
          const data = await response.json();
          setData(data);
        };
        getCards();
      }, []);
    
    console.log(selectedCard)
  return (
    <>
        <section className="cards-view">
            <div>
                {selectedCard ? (
                <>
                    <SelectedCard textColor={selectedCard.color} selectedImg={`../../images/${selectedCard.image}`} alt={selectedCard.alt}/>
                    
                </>
                ) : (
                    <>
                        <div className='view'>
                            <img className="logo" src={logo} alt="Logo" />
                            <ul className="view__message">
                                <li>1. Palun valige sobiv kaart ja seejärel täitke vasakul vajalikud väljad.</li>
                                <li>2. Kolmas väli on vabal valikul juhul kui esimesele reale enam kogu tekst ei mahu.</li>
                                <li>3. Sisendit täidetud vajutagae nuppu "Looge kaart" ning kaart laetakse brauseris alla.</li>
                            </ul>
                        </div>
                    </>
                )}
            </div>
            <div className="cards">
                <div className='card__list'>
                    {
                        data.map(item => (
                            <Card key={item.id} card={item} name={item.name} image={item.image} alt={item.alt} onSelect={handleCardSelect} />
                        ))
                    }
                </div>
            </div>
            
        </section>
    </>
  )
}

export default CardView
